@import "../../styles/variables";

.carouselButton {
  font-size: 46px;
  line-height: 0;
  display: block;
  width: 46px;
  height: 46px;
  padding: 0;
  z-index: 2;
  cursor: pointer;
  color: $blue;
  border: none;
  outline: none;
  background: transparent;
  & svg {
    width: 46px;
    height: 46px;
    transition: transform 0.2s ease-out;
  }
  &:hover, &:focus {
    color: #333333;
    outline: none;
    background: transparent;
  }
  &:hover svg {
    transform: scale(1.1);
  }
  &[disabled] {
    opacity: 0.25;
    cursor: default;
  }
  &[disabled]:hover svg {
    transform: scale(1);
  }
}
.dots {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  display: none;
  height: 12px;
  @media (min-width: $phone) {
    display: flex;
    flex-direction: row;
  }
  & li {
    position: relative;
    width: 12px;
    height: 12px;
    margin: 0 8px;
    cursor: pointer;
    & button {
      opacity: 0;
      width: 12px;
      height: 12px;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:before {
      display: block;
      content: "";
      font-size: 0;
      line-height: 0;
      width: 6px;
      height: 6px;
      cursor: pointer;
      color: transparent;
      border: 2px solid transparent;
      border-radius: 50%;
      outline: none;
      background: #333333;
      opacity: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.activeDot {
  &:before {
    border: 2px solid #333333 !important;
    background: transparent !important;
    width: 12px !important;
    height: 12px !important;
  }
}
.card {
  height: 600px;
  max-width: 100%;
  margin: 0 auto;
  @media (min-width: 1440px) {
    height: 600px;
    max-width: 295px;
  }
}
.cardInner {
  position: relative;
  height: 100%;
  & > img {
    width: 100%;
  }
}
.cardContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-Direction: column;
  align-Items: center;
  justify-Content: center;
  color: white;
  cursor: default;
  & .cardExtra {
    overflow: hidden;
    max-Height: 0;
    opacity: 0;
    transition: all .3s;
  }
  &:hover {
    & .cardExtra {
      overflow: hidden;
      max-Height: 100px;
      opacity: 1;
    }
  }
  & a {
    display: block;
    color: white;
    border: 2px solid white;
    border-Radius: 6px;
    padding: 8px 32px;
    font-Size: 14px;
  }
  & h3 {
    font-Family: $bodyText;
    font-Size: 1.5rem;
    line-Height: 2.25rem;
    text-Transform: uppercase;
    font-Weight: 500;
  }
  & hr {
    border: none;
    height: 2px;
    background-Color: white;
    width: 34px;
    margin-Bottom: 1rem;
  }
}
