@import "../styles/variables";
.contourWrapper {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.14;
  overflow: hidden;
  height: 100%;
  width: 100%;

  & svg {
    position: absolute;
    bottom: -254px;
    left: 50%;
    transform: translateX(-50%);
    max-height: 100%;
  }
}
.insetPanel {
  background-color: $lightGreen;
  padding: 32px;
  max-width: 1185px;
  margin: auto;
  position: relative;
  z-index: 1;
  @media (min-width: $desktop) {
    padding: 64px;
  }
}
.insetPanelContent {
  background-color: white;
  box-shadow: 0 0 20px 10px rgba($blue,0.1);
  @media (min-width: $phone) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.insetPanelBackground {
  width: 100%;
  padding: 0 0 100% 0;
  position: relative;
  @media (min-width: $tabletLand) {
    width: 300%;
    max-width: 356px;
    padding: 0 0 44% 0;
  }
}
.insetPanelInner {
  padding: 32px;
  position: relative;
  flex-grow: 1;
  @media (min-width: $desktop) {
    padding: 48px 32px 32px 48px;
  }
  h2 {
    max-width: 325px;
  }
  p {
    margin: 0 0 1rem 0;
  }
  .insetPanelStamp {
    display: none;
    & svg {
      max-height: 100%;
    }
    @media (min-width: $desktop) {
      position: absolute;
      top: 32px;
      right: 32px;
      display: block;
      width: 84px;
      height: 84px;
    }
  }
}
.insetPanelStatement {
  margin: 32px 0;
  @media (min-width: $desktop) {
    margin: 32px 32px 32px 0;
  }
  &.columns {
    @media (min-width: $desktop) {
      column-count: 2;
    }
  }
  & p:first-of-type {
    @media (min-width: $desktop) {
      margin: 0 0 1rem 0;
    }
  }
}
.insetPanelLinks {
  @media (min-width: $phone) {
    margin-left: 0;
  }
  @media (min-width: $tabletLand) {
    margin-left: 16px;
    display: flex;
  }
  @media (min-width: $desktop) {
    margin-left: 0;
  }
  li {
    margin: 0 0 16px 0;
    @media (min-width: $tabletLand) {
      max-width: 230px;
    }
    @media (min-width: $desktop) {
      margin: 0 32px 0 0;
      max-width: 100%;
    }
    & h4 {
      color: $blue;
      font-Size: 18px;
    }
    & a {
      color: $red;
      font-Size: 12px;
      text-decoration: underline;
    }
  }
}
.sectionHeading {
  font-Size: 2rem;
  line-Height: 2.5rem;
  color: $blue;
  position: relative;
  @media (min-width: $desktop) {
    font-Size: 2.25rem;
    line-Height: 3rem;
  }
  &:after {
    left: 40px;
  }
}
.tagLarge {
  color: $green;
  font-family: $bodyText;
  font-weight: 500;
  font-Size: 1rem;
  line-Height: 1.1rem;
  text-transform: uppercase;
  display: block;
  letter-spacing: 2px;
  margin-bottom: 16px;
}
