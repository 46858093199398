@import "../../styles/variables";
.tagPopupOption {
  border-top: 1px solid $grey1;
  padding: 1rem;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.25rem;
  display: block;
  color: $blue;
  font-family: Domaine, serif;
  font-weight: 500;
  text-transform: none;
  &:last-child {
    border-bottom: 1px solid $grey1;
    margin-bottom: 1rem;
  }
}
