@import "../../styles/variables";
.customArrow {
  width: 34px;
  height: 2px;
  background-color: $red;
  display: block;
  @media (max-width: $phone) {
    width: 1px;
    height: 40px;
    background-color: $grey1;
    margin: -23px 20px 0 0;
  }
}
.labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: $phone) {
    justify-content: left;
  }
  div {
    width: 130px;
    padding-left: 11px;
    @media (max-width: $phone) {
      width: auto;
      padding: 0;
    }
    &:first-child {
      @media (max-width: $phone) {
        width: 176px;
      }
    }
    span {
      display: block;
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      color: #333333;
      font-weight: 600;
      font-size: 12px;
      line-height: 1rem;
      margin-top: 4px;
    }
  }
}
