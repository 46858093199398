@import "../../styles/variables";

.discover {
  position: relative;
  z-Index: 1;
}
.discoverInner {
  padding: 0;
  @media (min-width: $desktop) {
    padding: 0 30px 0 0;
  }
  & p {
    font-weight: 300;
    font-Size: 14px;
    line-Height: 24px;
    &:first-child {
      margin-top: 28px;
    }
    &:last-of-type {
      margin-bottom: 36px;
    }
  }
}
.sectionHeading {
  font-Size: 2rem;
  line-Height: 2.5rem;
  color: $blue;
  @media (min-width: $desktop) {
    font-Size: 2.25rem;
    line-Height: 3rem;
  }
}
.small {
  font-Size: 14px;
}
.gridRight {
  justify-content: flex-end;
}
.withBorder {
  padding-bottom: 24px;
  position: relative;
  &:before {
    background-color: $red;
    width: 5.2rem;
    height: 2px;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.large {
  font-Size: 2.5rem;
  line-Height: 3.25rem;
  @media (min-width: $desktop) {
    font-Size: 3rem;
    line-Height: 3.75rem;
  }
}
.testimonialsIntro {
  & h2 {
    padding-left: 0;
    @media (min-width: $phone) {
      padding-left: 64px;
    }
    @media (min-width: 1600px) {
      padding-left: 0;
    }
  }
  & p {
    padding-left: 0;
    @media (min-width: $phone) {
      padding-left: 64px;
    }
  }
}
.testimonialsLink {
  text-decoration: underline;
  color: $green;
}
.tagLarge,
.tag {
  color: $green;
  font-family: $bodyText;
  font-weight: 500;
  font-Size: 1rem;
  line-Height: 1.1rem;
  text-transform: uppercase;
  display: block;
  letter-spacing: 2px;
}
.tagLarge {
  margin-bottom: 16px;
}
.images {
  position: relative;
  & img {
    display: block;
    position: relative;
    &:first-child {
      box-shadow: 1.45rem -1.45rem 0 0 $lightGreen;
      max-width: 172px;
      margin: 0 auto 98px;
      @media (min-width: $phone) {
        margin: 0 0 200px auto;
        max-width: 278px;
      }
    }
    &:nth-child(2) {
      position: absolute;
      left: 0;
      width: 144px;
      top: 60%;
      @media (min-width: $phone) {
        top: 80%;
        width: 253px;
        left: -20px;
      }
      @media (min-width: $tabletLand) {
        top: 80%;
        width: 253px;
        left: 40px;
      }
    }
  }
  & svg {
    position: absolute;
    top: 88%;
    width: 115px;
    left: calc(50% + 18px);
    @media (min-width: $phone) {
      width: 160px;
      top: 80%;
      left: calc(100% - 115px);
    }
    @media (min-width: $tabletLand) {
      width: 160px;
      top: 80%;
      left: calc(100% - 80px);
    }
  }
}
.aboutUs {
  width: 200px;
  display: flex;
  flex-Direction: row;
  align-Items: center;
  justify-Content: flex-end;
  text-Align: right;
  font-Weight: 600;
  font-Size: 1rem;
  line-Height: 24px;
  & svg {
    color: $red;
    height: 24px;
    margin-Left: 0.5rem;
  }
  & span {
    color: #44546A;
    cursor: pointer;
  }
}
.button {
  display: inline-block;
  font-Weight: 500;
  font-Size: 0.8rem;
  text-Align: center;
  border: 1px solid $red;
  border-Radius: 3px;
  color: $red;
  margin: 0 auto 1.25rem auto;
  font-family: $bodyText;
  outline: none;
  width: auto;
  transition: background-color .3s;
  & a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 1rem 1.81rem;
    color: $red;
    font-Family: Domaine, serif;
    font-Size: 1rem;
    transition: color .3s;
  }
  &:hover {
    background: $red;
    color: white;
    & a {
      color: white;
    }
  }
}
.centered {
  text-Align: center;
}
.popularWrapper {
  //display: none;
  @media (min-width: $phone) {
    display: flex;
  }
  @media (min-width: 960px) {
    display: block;
  }
}
.popular {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-Radius: 3px;
  flex: 1;
  &:first-of-type {
    margin-bottom: 2.69rem;
    @media (min-width: $phone) {
      margin-right: 20px;
    }
    @media (min-width: 960px) {
      margin-right: 0;
    }
  }
  &:last-of-type {
    margin-bottom: 2.69rem;
    @media (min-width: $tablet) {
      margin-bottom: 0;
    }
  }
  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border: 1px solid white;
    z-Index: 1;
  }
  & > img {
    width: 100%;
    display: block;
    transform: scale(1);
    transition: all .6s;
  }
  &:hover {
    & > img {
      transform: scale(1.2);
    }
  }
}
.popularNoBorder {
  border-radius: 6px;
  &:first-of-type {
    @media (min-width: $phone) {
      margin-right: 0;
    }
  }
  &:before {
    display: none;
  }
  &:hover {
    & > img {
      transform: scale(1);
    }
  }
}
.popularImage {
  //display: none !important;
  opacity: 1;
  @media (min-width: $phone) {
    display: block !important;
    //opacity: 0;
  }
}
.popularBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  @media (max-width: $phone) {
    position: static;
    height: auto;
    //padding: 0 0 56%;
  }
}
.popularInner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
  color: white;
  & h3 {
    font-family: $bodyText;
    font-Size: 1rem;
    line-Height: 1.5rem;
    font-weight: 500;
    margin: 0 0 10px;
    @media (min-width: $desktop) {
      line-Height: 3rem;
      font-Size: 1.5rem;
      color: white;
    }
  }
  & p {
    margin: 0;
    font-Size: 1rem;
    line-Height: 1.25rem;
    @media (min-width: $desktop) {
      font-Size: 1.25rem;
      line-Height: 1.5rem;
    }
  }
  & svg {
    height: 18px;
    margin-bottom: 6px;
  }
}
.popularHeroInner {
  width: 60%;
  @media (max-width: $phone) {
    position: static;
    width: 100%;
    transform: none;
    background: white;
    color: black;
    padding: 20px;
  }
  & h3 {
    font-Size: 1.5rem;
    line-Height: 2.25rem;
    color: $blue;
    @media (min-width: $phone) {
      font-Size: 2.625rem;
      line-Height: 3.375rem;
      color: white;
    }

    @media (min-width: $desktop) {
      font-Size: 2.625rem;
      line-Height: 3.375rem;
      color: white;
    }
  }
  & p {
    font-Size: 1rem;
    line-Height: 1.2rem;
    @media (min-width: $phone) {
      font-Size: 1.125rem;
      line-Height: 1.5rem;
    }
  }
}
.buttonWrapper {
  margin: 32px 0 0 0 !important;
}
.experiencesWrapper {
  position: relative;
  padding-top: 2.8rem;
  margin-right: 0;
  margin-left: 0;
  @media (min-width: $phone) {
    margin-right: 32px;
    margin-left: 32px;
    padding-top: 8rem;
  }
  & > img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    @media (min-width: $phone) {
      max-width: 60%;
    }
    @media (min-width: $desktop) {
      max-width: 80%;
      width: auto;
    }
  }
}
.destinationsWrapper {
  position: relative;
  padding-top: 2.8rem;
  margin-top: 32px;
  //margin-right: 40px;
  //margin-left: 40px;
  @media (min-width: $phone) {
    margin-right: 32px;
    margin-left: 32px;
    padding-top: 7rem;
  }
  & > img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    @media (min-width: $phone) {
      max-width: 60%;
    }
    @media (min-width: $desktop) {
      top: 0;
      max-width: 80%;
      width: auto;
    }
  }
}
.watermark {
  width: 360px;
  position: absolute;
  top: 46%;
  left: 3%;
  & svg {
    width: 100%;
    max-height: 100%;  }
}
.wellnessHoliday {
  background-color: $red;
  color: white;
  padding: 24px;
  box-shadow: 6px 6px 18px 12px rgba(208,208,208,1);
  position: relative;
  & h2 {
    font-family: $bodyText;
    font-Size: 30px;
    line-Height: 48px;
    padding: 34px 15px;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid white;
    text-align: center;
    @media (min-width: $phone) {
      text-align: left;
    }
  }
  & svg {
    position: absolute;
    width: 97px;
    top: calc(100% - 60px);
    left: calc(100% - 60px);
    max-height: 100%;
  }
}
.wellnessHolidayOptions {
  color: #00243f;
  @media (max-width: $tabletLand) {
    text-align: center;
  }
  & > svg {
    height: 8px;
    margin-bottom: 32px;
    @media (min-width: $tabletLand) {
      height: 12px;
      margin-bottom: 32px;
    }
  }
  & > div {
    font-Size: 1.8rem;
    line-Height: 48px;
    font-family: Domaine, serif;
    margin: 0 0 1rem 0;
    @media (min-width: $tabletLand) {
      font-Size: 2.1rem;
      line-Height: 58px;
    }
    & > div {
      display: inline-block;
      position: relative;
      color: #016D81;
      text-decoration: underline;
      cursor: pointer;
      @media (max-width: $phone) {
        width: 100%;
      }
      & svg {
        width: 18px !important;
      }
    }
  }
}
.blogButton {
  text-align: center;
  & a {
    display: inline-block;
    font-family: DomaineNarrow, serif;
    color: $blue;
    padding: 17px 42px;
    border: 1px solid $blue;
    border-radius: 3px;
    font-Size: 18px;
  }
}
.contourBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.14;
  overflow: hidden;
  & svg:first-child {
    position: absolute;
    top: -140px;
    left: 50%;
    transform: translateX(-2212px);
    display: none;
    @media (min-width: $phone) {
      display: block;
    }
  }
  & svg:last-child {
    position: absolute;
    top: 449px;
    left: 50%;
    transform: translateX(-100px);
    @media (min-width: $phone) {
      top: -449px;
      transform: translateX(191px);
    }
  }
}
