@import "../../styles/variables";
.headerSearch {
  @media (min-width: $tablet) {
    position: fixed;
    z-index: 11;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px;
  }
}
.headerSearchBarWrapper {
  padding: 21px;
  @media (max-width: $tablet) {
    //display: none;
  }
  .headerSearchBar {
    background-color: $blue;
    border: 1px solid $blue;
    border-radius: 20px;
    z-index: 2;
    padding: 12px 25px;
    font-family: Domaine, serif;
    font-size: 1.5rem;
    color: $white;
    line-height: 1.8rem;
    font-weight: 400;
    box-shadow: $shadow;
    .dates {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 1rem 0;
      @media (min-width: $tablet) {
        padding: 0 1rem;
        margin: 0 1rem;
        border-left: 1px solid $white;
        border-right: 1px solid $white;
      }
      hr {
        width: 34px;
        height: 3px;
        background-color: $red;
        display: block;
        border: none;
        margin: 0 .5rem;
      }
    }
    @media (min-width: $phone) {
      display: flex;
      cursor: pointer;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-radius: 50px;
    }
  }
}

.editSearchBarWrapper {
  margin: 2rem 0.8rem;
  position: relative;
  z-index: 10;
  @media (min-width: $tablet) {
    margin: 0;
  }
  @media (min-width: 1280px) {
    width: 870px;
  }
  @media (min-width: $tablet) {
    position: fixed;
  }
  .editSearchBar {
    background-color: $offWhite;
    border: 1px solid #CCCCCC;
    border-radius: 20px;
    z-index: 2;
    padding: 12px 25px;
    box-shadow: $shadow;
    @media (max-width: $phone) {
      padding: 12px 20px;
    }
    @media (min-width: $tablet) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-radius: 50px;
    }
    .select {
      width: 100%;
      margin-bottom: 1rem;
      @media (min-width: $tablet) {
        width: auto;
        margin-bottom: 0;
      }
      select {
        font-family: Domaine, serif;
        font-size: 1.5rem;
        color: $blue;
        line-height: 1.8rem;
      }
    }
    .dates {
      @media (min-width: $tablet) {
        padding: 0 1rem;
        margin: 0 1rem;
        border-left: 1px solid $blue;
        border-right: 1px solid $blue;
      }
    }
    .guests {
      .guestsInput {
        font-family: Domaine, serif;
        font-size: 1.5rem;
        color: $blue;
        line-height: 1.8rem;
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        > svg {
          width: 1.5rem;
          height: 1.5rem;
        }
        > span {
          display: inline-block;
          @media (min-width: $tablet) {
            margin: 0 .5rem;
          }
        }
      }
    }
    button {
      border-radius: 50px;
      padding: 1rem 2rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -70px;
      z-index: 2;
    }
  }
  .overlay {
    position: fixed;
    top: 98px;
    left: 0;
    width: 100%;

    //background: rgba($white, 0.4);
    text-align: center;
    @media (min-width: $tablet) {
      height: calc(100% - 98px);
    }
  }
}
.addGuests {
  width: 24px;
  height: 24px;
  margin-left: 0.5rem;
  font-size: 24px;
  cursor: pointer;
  & svg {
    color: #00243F;
    width: 24px;
  }
}
.guestsPopupOption {
  border-top: 1px solid $grey1;
  padding: 1rem;
  //display: flex;
  //align-items: center;
  //justify-content: space-between;
  h4 {
    font-size: 18px;
    line-height: 22px;
    color: #00243F;
    font-weight: normal;
    position: relative;
    span {
      display: block;
      color: #666666;
      font-size: 12px;
      line-height: 15px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
    }
  }
  .optionSelect {
    margin-bottom: 1rem;
    div {
      display: flex;
      align-items: center;
      h5 {
        font-size: 30px;
        line-height: 36px;
        color: #00243F;
        font-weight: normal;
        margin-left: .5rem;
      }
    }
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        margin: 0.5rem 0;
        background-color: $offWhite;
        width: calc(50% - 1rem);
        padding: .5rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        > div {
          margin-top: 0.5rem;
        }
      }
    }
    h6 {
      font-size: 0.8rem;
      font-family: $bodyText;
      font-weight: 500;
      margin-top: 1rem;
      color: $blue;
    }
  }
  &.rightAligned {
    text-align: right;
  }
}
.addRoom {
  padding: .5rem 1rem;
  div {
    position: relative;
    text-align: center;
    cursor: pointer;
    &:before, &:after {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 1px;
      width: calc(50% - 37px);
      background-color: $blue;
      content: "";
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
    svg {
      color: $blue;
      width: 32px;
    }
  }
  p {
    font-size: 0.8rem;
    margin-top: 0;
    text-align: center;
  }
}
.removeRoom {
  position: absolute;
  right: 1rem;
  top: 50%;
  margin-top: -8px;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &:before, &:after {
    position: absolute;
    left: 7px;
    content: ' ';
    height: 17px;
    width: 2px;
    background-color: $blue;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
