@import "../../styles/variables";

.carouselButton {
  font-size: 40px;
  line-height: 0;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  z-index: 2;
  cursor: pointer;
  color: $white;
  border: none;
  outline: none;
  background: transparent;
  & svg {
    width: 40px;
    height: 40px;
    transition: transform 0.2s ease-out;
  }
  &:hover, &:focus {
    color: $white;
    outline: none;
    background: transparent;
  }
  &:hover svg {
    transform: scale(1.1);
  }
  &[disabled] {
    opacity: 0.25;
    cursor: default;
  }
  &[disabled]:hover svg {
    transform: scale(1);
  }
}
.dots {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  display: none;
  height: 12px;
  @media (min-width: $phone) {
    display: flex;
    flex-direction: row;
  }
  & li {
    position: relative;
    width: 12px;
    height: 12px;
    margin: 0 8px;
    cursor: pointer;
    & button {
      opacity: 0;
      width: 12px;
      height: 12px;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:before {
      display: block;
      content: "";
      font-size: 0;
      line-height: 0;
      width: 6px;
      height: 6px;
      cursor: pointer;
      color: transparent;
      border: 2px solid transparent;
      border-radius: 50%;
      outline: none;
      background: $white;
      opacity: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.activeDot {
  &:before {
    border: 2px solid $white !important;
    background: transparent !important;
    width: 12px !important;
    height: 12px !important;
  }
}