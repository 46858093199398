@import "../../styles/variables";

.slider {
  margin-bottom: 45px;
}
.carouselButton {
  font-size: 46px;
  line-height: 0;
  display: block;
  width: 46px;
  height: 46px;
  padding: 0;
  z-index: 2;
  cursor: pointer;
  color: #333333;
  border: none;
  outline: none;
  background: transparent;
  & svg {
    width: 46px;
    height: 46px;
    transition: transform 0.2s ease-out;
  }
  &:hover, &:focus {
    color: #333333;
    outline: none;
    background: transparent;
  }
  &:hover svg {
    transform: scale(1.1);
  }
  &[disabled] {
    opacity: 0.25;
    cursor: default;
  }
  &[disabled]:hover svg {
    transform: scale(1);
  }
}
.dots {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  display: none;
  height: 12px;
  @media (min-width: $phone) {
    display: flex;
    flex-direction: row;
  }
  & li {
    position: relative;
    width: 12px;
    height: 12px;
    margin: 0 8px;
    cursor: pointer;
    & button {
      opacity: 0;
      width: 12px;
      height: 12px;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:before {
      display: block;
      content: "";
      font-size: 0;
      line-height: 0;
      width: 6px;
      height: 6px;
      cursor: pointer;
      color: transparent;
      border: 2px solid transparent;
      border-radius: 50%;
      outline: none;
      background: #333333;
      opacity: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.activeDot {
  &:before {
    border: 2px solid #333333 !important;
    background: transparent !important;
    width: 12px !important;
    height: 12px !important;
  }
}
.card {
  max-width: 90%;
  min-height: 355px;
  @media (min-width: $phone) {
    max-width: 326px;
  }
}
.cardInner {
  position: relative;
  & > img {
    display: block;
    position: absolute;
    width: 84px;
    top: 30px;
    left: 0;
  }
}
.cardQuote {
  background-color: rgba(255,255,255,0.6);
  margin: 0 0 0 40px;
  padding: 60px 25px 25px 60px;
  & h3{
    font-size: 24px;
    margin-bottom: 1rem;
  }
  & footer {
    & p {
      margin: 0;
    }
  }
}